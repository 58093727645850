export interface SettingsGroupName {
    name: string;
    descr?: string;
}

export interface SettingsGroup {
    group: string;
    description?: string;
    names?: SettingsGroupName[];
}

export interface SettingsDtoInterface {
    _id: string;
    groups: SettingsGroup[];
}
