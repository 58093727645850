interface FormValue {
    name: string;
    value: string;
}

export interface DebtEditFormRef {
    statuses: FormValue[];
}

export interface DebtEditFormData {
    _id: string;
    debt?: number;
    price: number;
    status: string;
    refund?: number;
    formRef?: DebtEditFormRef;
    isRefundEndCountry: boolean;
    isCountryEnd: boolean;
    daysPayedEnd?: Date;
    daysEnd?: Date;
    daysDebt?: number;
    gasolineStart?: number;
    gasolineEnd?: number;
    gasolineDebt?: number;
    mileagePayedEnd?: number;
    mileageEnd?: number;
    mileageDebt?: number;
}
