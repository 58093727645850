export interface EmailRecipientsSettings {
    configType: 'settings';
    group: 'email';
    name: 'recipients';
    report: ReportType;
}

interface ReportType {
    [key: string]: {
        name: string;
        recipients: string[];
    };
}
