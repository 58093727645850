import { CityTransportParks } from '@shared/models';

interface FormValue {
    name: string;
    value: string;
}

export interface VehicleEditFormRef {
    colors: FormValue[];
    equipments: FormValue[];
    engines: FormValue[];
    transportParks: CityTransportParks[];
    transportParkIsDisabled: boolean;
}

export interface VehicleStatusEditFormRef {
    statuses: FormValue[];
}

export interface VehicleEditFormData {
    _id: string;
    sim_id: string;
    color: string;
    equipment: string;
    engine: string;
    transport_park: string;
    formRef?: VehicleEditFormRef;
}
