interface TransportParkFormValue {
    name: string;
    value: string;
}

export interface CityTransportParks {
    name: string;
    value: string;
    transport_parks: TransportParkFormValue[];
}
