export interface City {
    name: string;
    value: string;
    active: boolean;
}

export interface CountryFormValue {
    name: string;
    value: string;
    cities: City[];
}

export interface CountriesWithCities {
    countries: CountryFormValue[];
}
