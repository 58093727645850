type DiscountUnitType = 'rub' | 'pct';

export interface UserDiscount {
    _id: string;
    value: number;
    unit: DiscountUnitType;
    description: string;
    type: string;
    status: string;
    groups: string[];
}
