export interface DebtUpdateRequestDto {
    price?: number;
    total?: Total;
    status?: string;
}

export interface Total {
    debt: number;
    refund: number;
}
