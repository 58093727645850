export interface MediaFile {
    _id: string;
    name: string;
    fieldname: string;
    originalname: string;
    mimetype: string;
    filename: string;
    size: number;
    path: string;
    destination: string;
    dateIns: string | Date;
    group: string;
    data?: {
        type: string;
        [key: string]: any;
    };
}

export interface MediaGroup {
    _id: string;
    media: MediaFile[];
}

export interface MediaFileFormatted {
    url: string;
    [key: string]: any;
}

export type MediaGroupFormatted = MediaGroup & {
    media: MediaFileFormatted[];
};

export interface SettingsMedia {
    [key: string]: MediaFile[];
}
