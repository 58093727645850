export interface CustomerDocument {
    name: string;
    type: string;
    date: string;
    ext: string;
    session?: CustomerSession;
    info?: any;
}

export interface CustomerSession {
    _id?: string;
    type_order?: string;
    date_start?: string;
    date_end?: string;
    status_session?: string;
    dates?: Required<{ start: number; end?: number }>;
    vehicle?: {
        license: string;
        model: string;
    };
}

export type CustomerSessionCustom = CustomerSession & {
    sessionId?: string;
    docs?: CustomerDocument[];
    start: string;
    end?: string;
};
