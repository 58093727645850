/** Долгота */
export type Longitude = number;
/** Широта */
export type Latitude = number;

export type GeoJsonCoordinate = [Longitude, Latitude];

export interface GeoJsonGeometryBase {
    type: string;
}

export interface GeoJsonGeometryLineString extends GeoJsonGeometryBase {
    type: 'LineString';
    coordinates: GeoJsonCoordinate[];
}

export interface GeoJsonGeometryPolygon extends GeoJsonGeometryBase {
    type: 'Polygon';
    coordinates: GeoJsonCoordinate[][];
}

export interface GeoJsonGeometryPoint extends GeoJsonGeometryBase {
    type: 'Point';
    coordinates: GeoJsonCoordinate;
}

export type GeoJsonGeometry = GeoJsonGeometryLineString | GeoJsonGeometryPolygon | GeoJsonGeometryPoint;

/** Тип геометрии */
export type GeometryType = GeoJsonGeometry['type'];

export interface GeoJsonFeatureBase {
    type: 'Feature';
    properties: object | null;
    geometry: GeoJsonGeometryBase;
}

export interface GeoJsonFeature<
    Property extends object | null = null,
    Geometry extends GeoJsonGeometryBase = GeoJsonGeometry,
> extends GeoJsonFeatureBase {
    properties: Property;
    geometry: Geometry;
}

/**
 * GeoJSON: https://ru.wikipedia.org/wiki/GeoJSON
 */
export interface GeoJson<Feature extends GeoJsonFeatureBase = GeoJsonFeature> {
    type: string;
    features: Feature[];
}
