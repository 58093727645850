import { OrganizationsBill } from '@shared/models';

export interface OrganizationDto {
    _id: string;
    customer_owner: string;
    customer_phone: number;
    customer_id: number;
    status: number;
    created: Date | string;
    update: Date | string;
    drivers: Driver[];
    CEOName: string;
    KPP: string;
    OGRN: string;
    bank: Bank;
    contactPerson: ContactPerson;
    fromDD: FromDD;
    legalEntityAddress: string;
    legalEntityMailAddress: string;
    legalEntityName: string;
    legalEntityRegistrationDate: string;
    legalEntityShortName: string;
    legalEntityType: string;
    orgType: string;
    taxIdentificationNumber: string;

    signsOfOneDay?: string;
    turnoverPerYear?: number;
    packageOfConstituentDocuments?: Document[];
    packageOfConstituentDocumentsDescription?: string;
    approvalDate?: string;
    serviceDeskPartnerId?: string;
    serviceDeskTicket?: string;
    approvalDocument?: Document[];
    masterContractDate?: string;
    masterContractNumber?: string;
    masterContractFlies?: Document[];
    masterContractSignetFlies?: Document[];
    invoiceDate?: string;
    invoiceNumber?: string;
    invoiceSum?: string;
    paymentList?: string;
    depositPaymentDate?: string;
    depositPaymentStatus?: string;
    incomingCorrespondenceDate?: string;
    originalDocumentsReceived?: boolean;

    bills: OrganizationsBill[];
    statusPerson: string;
    contactPersonFio: string;
    contactPersonPhone: string;
    contactPersonEmail: string;
}

interface Document {
    fileName: string;
    originalName: string;
    dateInsert: Date | string;
}

interface Driver {
    customer: string;
    phone: number;
    created: Date | string;
}

interface ContactPerson {
    fio: string;
    phone: number;
    email: number;
}

interface FromDD {
    value: string;
    unrestricted_value: number;
    data: FromDDdata;
}

interface FromDDdata {
    kpp: string;
    capital: string;
    management: FromDDDataManagement;
    founders: any;
    managers: any;
    branch_type: string;
    branch_count: number;
    source: any;
    qc: any;
    hid: string;
    type: string;
    state: FromDDDataState;
    opf: FromDDDataOpf;
    name: FromDDDataName;
    inn: any;
    ogrn: string;
    okpo: string;
    okved: string;
    okveds: any;
    authorities: any;
    documents: any;
    licenses: any;
    finance: FromDDDataFinance;
    address: FromDDDataAddress;
    phones: any;
    emails: any;
    ogrn_date: number;
    okved_type: string;
    employee_count: number;
}

interface FromDDDataFinance {
    tax_system: any;
    income: any;
    expense: any;
    debt: any;
    penalty: any;
}

interface FromDDDataAddress {
    value: string;
    unrestricted_value: string;
    data: any;
}

interface FromDDDataState {
    status: string;
    actuality_date: number;
    registration_date: number;
    liquidation_date: number;
}

interface FromDDDataOpf {
    type: string;
    code: string;
    full: string;
    short: string;
}

interface FromDDDataName {
    full_with_opf: string;
    short_with_opf: string;
    latin: string;
    full: string;
    short: string;
}

interface FromDDDataManagement {
    name: string;
    post: string;
    disqualified: any;
}

interface Bank {
    BIK: string;
    bankAddress: string;
    bankName: string;
    correspondentAccount: string;
    paymentAccount: string;
}
