import { Moment } from 'moment';
import { AllSessionTypes, Driver, SessionParameters, VehicleItem } from '@shared/models';
import { Customer } from '@pages/shared/customer/store/customers.interfase';

export interface SessionLimitations {
    type: string;
    priceType: string;
    fine: {
        count: number;
        price: number;
    };
    value: number;
}

export interface SessionDevice {
    geolocation: {
        type: string;
        coordinates: number[];
    };
    mileage: number;
    level_fuel: number;
    speed: number;
    doors: string;
    height: number;
    angle_direction: number;
}

export interface SessionPayQueueItem {
    date_start: string | Date | Moment;
    date_end: string | Date | Moment;
    status: string;
    promotionType?: string;
    limit_mileage: number;
    device?: SessionDevice;
    order_id?: string;
    price: number;
    mileage: number;
    prolonged: boolean;
    addedByOperator?: boolean;
    // covid-discount
    discount?: number;
    discountReason?: string;
    addMileage?: number;
    limit_mileage_base?: number;
    isAdded?: boolean;
}

export interface Session {
    _id: string;
    _id_prolong?: string;
    _id_organization?: string;
    order_id: string;
    user_id: number;
    vehicle_id: number;
    type_order: AllSessionTypes;
    status_session: string;
    last_id: number;
    device_data?: {
        create?: SessionDevice;
        checking?: SessionDevice;
        start?: SessionDevice;
        end?: SessionDevice;
    };
    docnum?: string;
    limit_mileage: number;
    date_created: string | Date | Moment;
    date_start: string | Date | Moment;
    date_end: string;
    date_end_origin: string | Date | Moment;
    date_new?: string | Date | Moment;
    off_set_days?: number;
    vehicle_price: number;
    price: {
        type: string;
        description: string;
        duration: number;
        mileage: number;
        price: number;
        period: number[];
    };
    discounts: {
        engines?: any[];
        equipments?: any[];
        colors?: any[];
    };
    user_discounts: [];
    limitations?: SessionLimitations[];
    options?: string[];
    pay_queue?: SessionPayQueueItem[];
    lease_contract?: string;
    dates?: {
        check?: number;
        start?: number;
        end?: number;
    };
    date_finished: string | Date | Moment;
    haveCOVIT19Discount?: boolean | number;
    drivers?: Driver[];
    driversData?: any[];
    add_agreement?: string;
    documents: { ident: string; ru: string; file: string };
    delivery?: any;
    delivery_address?: any;
    params?: SessionParameters;
    customer?: Customer;
    childrenSessions?: Session[];
    userRef?: SessionUserRef;
    vehicleIdsHistory: VehicleIdsHistory[];
    timeZone: number;
}

export interface VehicleIdsHistory {
    data: string;
    license: string;
    vin: string;
    vehicle: VehicleItem;
}

export interface SessionUserRef {
    authkey?: string;
    balance?: number;
    date_login?: number;
    date_registration?: number;
    dlicense?: any;
    dp_points?: number;
    dr_region?: any;
    email?: string;
    fcmTokens?: string[];
    fcmTokensProj?: any;
    isFake?: boolean;
    last_id?: number;
    notification?: boolean;
    number?: number;
    ops?: number;
    passport?: SessionUserRefPasport;
    phoneNumber?: string;
    place_registration?: any;
    project?: string[];
    reason?: string;
    registerOnProject?: string;
    scoring?: any;
    scoringLog?: any;
    scoringSumsub?: any;
    smsGuids?: any;
    status?: number;
    type?: string;
    ucsinfo?: any;
    _id?: string;
}

export interface SessionUserRefPasport {
    age?: any;
    birth_date?: string;
    citizenship?: string;
    dlRiskScore?: any;
    firstNameEn?: string;
    first_name?: string;
    gender?: string;
    lastFaceMatchConfidence?: any;
    lastNameEn?: string;
    last_name?: string;
    middleNameEn?: string;
    middle_name?: string;
    passport_department_code?: string;
    passport_issue_date?: string;
    passport_issued?: string;
    passport_number?: string;
    passport_serial?: string;
    photo?: string[];
    place_date?: string;
}
