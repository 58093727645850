export type UserDebtType =
    | 'DEBT_CANCEL_RENT'
    | 'DEBT_ID20'
    | 'DEBT_END_COUNTRY'
    | 'DEBT_EVACUATION'
    | 'DEBT_DAMAGE'
    | 'REFUND_CANCEL'
    | 'DEBT_END_MILEAGE'
    | 'REQUEST_CHANGE_PRICE'
    | 'DEBT_END_SESSION_EARLY'
    | 'DEBT_LOSS_OF_ACCESSORES'
    | 'MONTH_PAYMENT'
    | 'DEBT_MILEAGE'
    | 'SURCHARGE_MONTH_PAYMENT'
    | 'START_SESSION'
    | 'REFUND_END_COUNTRY';

export type UserDebtStatus = 'PAYED' | 'SUSPENSE' | 'CREATE' | 'CANCEL';
export type UserDebtCreator = 'SYSTEM' | 'OPERATOR' | 'USER';
type UserDebtDetailsFineType = 'after_start' | 'before_start';

interface UserDebtDamageDetail {
    dateMail: Date;
    mailNum: string;
    description: string;
}

interface UserDebtEndCountryDetail {
    payed: {
        start: string;
        end: string;
        mileage: number;
        payed: number;
    };
    days: {
        start: string;
        end: string;
        days: number;
        debt: number;
        refund: number;
    };
    mileage: {
        start: number;
        end: number;
        debt: number;
    };
    gasoline: {
        start: number;
        end: number;
        debt: number;
        refund: number;
    };
    total: {
        debt: number;
        refund: number;
    };
    _id_sessions: string[];
}

interface UserDebtRefundCancelDetail {
    type_order: 'country';
    date_start: Date;
    date_cancel: Date;
    vehicle_price: number;
    fine_type: UserDebtDetailsFineType;
    fine: number;
}

interface UserDebtEndMileageDetail {
    over_limit: number;
    month_mileage: number;
    price: number;
    endMileage: number;
    startMileage: number;
}

interface UserDebtMonthPaymentDetail {
    date_start: Date;
    date_end: Date;
    limit_mileage: number;
    discount: number;
    addMileage: number;
    discountReason: string;
}

interface UserDebtStartSessionDetail {
    date_start: Date;
    date_end: Date;
    limit_mileage: number;
    type_order: 'country';
}

interface UserDebtCancelRentDetails {
    type_order: 'freedom';
    date_start: Date;
    date_cancel: Date;
    vehicle_price: number;
    fine_type: UserDebtDetailsFineType;
    fine: number;
    email_id: number;
    claim_pdf: string;
    claim_email: Date;
}

export interface UserDebtId20 {
    // type: 'DEBT_ID20',
    resolution_number: string;
    photos: string[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    reservation_sessionType: 'freedom';
    priceType: string;
    priceDuration: number;
    customerName: null;
    _id_vehicle: string;
    projectId: { project: 'mobility' };
    vehicleName: string;
    vehicleLicense: string;
    id20_total_amount: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    id20_fineId: string;
    id20_to_pay: boolean;
    id20_to_pay_datetime: string;
    id20_discount_size: number;
    id20_discount_date: string;
    id20_discount_paid: number;
    price_percents: number;
    attemptsToWriteOff: number;
    savedPhotos: string[];
}

interface UserDebtRefundEndCountry {
    // type: 'REFUND_END_COUNTRY',
    details: UserDebtEndCountryDetail;
    refund: boolean;
}

interface UserDebtStartSession {
    // type: 'START_SESSION';
    details: UserDebtStartSessionDetail;
}

interface UserDebtSurchargeMonthPayment {
    // type: 'SURCHARGE_MONTH_PAYMENT';
    details: {
        issue: string;
    };
}

interface UserDebtDebtMileage {
    // type: 'DEBT_MILEAGE';
    details: UserDebtEndMileageDetail;
    refund?: boolean | null; // CancelRent, RefundCancel, MONTH_PAYMENT, DEBT_MILEAGE
    writeoff?: Record<number, string>; // CancelRent, RefundCancel, MONTH_PAYMENT, DEBT_MILEAGE
}

interface UserDebtMonthPayment {
    // type: 'MONTH_PAYMENT';
    details: UserDebtMonthPaymentDetail;
    refund?: boolean | null; // CancelRent, RefundCancel, MONTH_PAYMENT, DEBT_MILEAGE
    writeoff?: Record<number, string>; // CancelRent, RefundCancel, MONTH_PAYMENT, DEBT_MILEAGE
}

interface UserDebtLossOfAccessores {
    // type: 'DEBT_LOSS_OF_ACCESSORES';
    details: unknown;
}

interface UserDebtEndSessionEarly {
    // type: 'DEBT_END_SESSION_EARLY';
    details: null;
}

interface UserDebtChangePrice {
    // type: 'REQUEST_CHANGE_PRICE';
    details: { issue: string };
}

interface UserDebtEndMileage {
    // type: 'DEBT_END_MILEAGE';
    details: UserDebtEndMileageDetail;
}

interface UserDebtRefundCancel {
    // type: 'REFUND_CANCEL';
    details: UserDebtRefundCancelDetail;
    refund: boolean;
    writeoff?: Record<number, string>; // CancelRent, RefundCancel, MONTH_PAYMENT, DEBT_MILEAGE
}

interface UserDebtDamage {
    // type: 'DEBT_DAMAGE';
    details: UserDebtDamageDetail;
    // 'refund': null,
    // 'writeoff': null,
    attemptsToWriteOff: 2;
    nextWriteOffOn: unknown;
    operator: string;
}

interface UserDebtEvacuation {
    // type: 'DEBT_EVACUATION';
    details: NonNullable<unknown>;
}

interface UserDebtEndCountry {
    // type: 'DEBT_END_COUNTRY';
    details: UserDebtEndCountryDetail;
}

export interface UserDebtCancelRent {
    // type: 'DEBT_CANCEL_RENT';
    details: UserDebtCancelRentDetails;
    refund?: boolean | null;
    writeoff?: Record<number, string>;
}

export type UserDebtTypes =
    | UserDebtCancelRent
    | UserDebtEndCountry
    | UserDebtEvacuation
    | UserDebtDamage
    | UserDebtRefundCancel
    | UserDebtEndMileage
    | UserDebtChangePrice
    | UserDebtEndSessionEarly
    | UserDebtMonthPayment
    | UserDebtLossOfAccessores
    | UserDebtDebtMileage
    | UserDebtSurchargeMonthPayment
    | UserDebtStartSession
    | UserDebtRefundEndCountry
    | UserDebtId20;

export interface UserDebtCommon {
    _id: string;
    type: UserDebtType;
    typeRu?: string;
    user_id: number;
    vehicle_id: number;
    creator: UserDebtCreator;
    status: UserDebtStatus;
    statusRu?: string;
    date_debt: Date;
    date_create: Date;
    date_pay?: Date; // n/a in:  UserDebtEvacuation
    _id_session?: string; // n/a in: UserDebtChangePrice
    _id_ucs?: string; // n/a in:  UserDebtEvacuation
    _id_order?: string; // n/a in:  UserDebtEvacuation
    order_id?: string; // n/a in:  UserDebtEvacuation
    price: number;
}
