import { MediaFile } from './media-group';

export type MediaBanner = MediaFile & {
    data?: {
        title: string;
        body: string;
        active: boolean;
        [key: string]: any;
    };
};

export type NewMediaBanner = MediaBanner & {
    fieldname?: string;
    originalname?: string;
    mimetype?: string;
    filename?: string;
    size?: number;
    path?: string;
    destination?: string;
    dateIns?: string | Date;
    group?: string;
    data?: {
        title: string;
        body: string;
        active: boolean;
        [key: string]: any;
    };
};
