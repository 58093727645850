export interface Receipt {
    _id: string;
    operation: string;
    _id_order: string;
    _id_session: string;
    _id_user: string;
    _id_vehicle: string;
    user_id: number;
    vehicle_id: number;
    date_create: Date;
    date_send: Date;
    date_report: Date;
    uuid: string;
    type: string;
    status: string;
    status_msg?: string;
    status_description: string;
    atolParams: AtolParams;
    report: ReceiptReport;
    comment?: string;
    byAdditionalAgreement?: boolean;
}

interface AtolParams {
    external_id: string;
    clientInfo: {
        email: string;
        phone: string;
    };
    tariff: {
        description: string;
        price: number;
    };
}

interface ReceiptReport {
    callback_url: string;
    daemon_code: string;
    device_code: string;
    warnings: any;
    error: any;
    external_id: string;
    group_code: string;
    payload: {
        ecr_registration_number: string;
        fiscal_document_attribute: number;
        fiscal_document_number: number;
        fiscal_receipt_number: number;
        fn_number: string;
        fns_site: string;
        receipt_datetime: string;
        shift_number: number;
        total: number;
        ofd_inn: string;
        ofd_receipt_url: string;
    };
    status: string;
    uuid: string;
    timestamp: string;
}
