export interface FineUnit {
    id: string;
    _id: string;
    photos?: string[];
    savedPhotos?: string[];

    typeRu: string;
    statusRu: string;

    koap_code: string;
    koap_text: string;
    resolution_number: string;
    date_decision: Date;
    date_offense: Date;
    discount_date: Date;
    discount_size: number;
    ispaid: boolean;
    ispaid_id20: boolean;
    location: string;

    payment_bank_account: string;
    payment_bank_name: string;
    payment_bik: string;
    payment_division: string;
    payment_inn: string;
    payment_issuer_name: string;
    payment_kbk: string;
    payment_kpp: string;
    payment_oktmo: string;
    payment_purpose: string;
    payment_receiver_name: string;
    source_type: string;
    to_pay: boolean;
    to_pay_datetime: Date;
    total_amount: number;
    user_email: string;
    user_name: string;
    vehicle_id: number;
    vehicle_number: string;
    vin: string;
    usersFinesId20ManualMerge: [];

    sessionId?: string;
}
