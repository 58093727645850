export interface SessionOrder {
    _id: string;
    start_data: number;
    end_data: number;
    user_id: number;
    type_order: string;
    status: string;
    status_msg?: string;
    order_desc: string;
    vehicle_model: string;
    vehicle_id: number;
    city: string;
    order_id: string;
    reservation_day: number;
    limit_mileage: number;
    total: number;
    options: string[];
    price_info: SessionOrderPriceInfo;
    date_created: Date | string;
    half_price: boolean;
    orderBundle: OrderBundle;
    last_id: number;
    rbsuat_order_id: string;
    session_id: string;
    debts: any;
}

interface OrderBundle {
    cartItems: OrderBundleCartItems;
}

interface OrderBundleCartItems {
    items: CartItemsItems[];
}

export interface CartItemsItems {
    positionId: number;
    name: string;
    quantity: {
        value: number;
        measure: string;
    };
    itemCurrency: string;
    itemCode: string;
    tax: {
        taxType: number;
        taxSum: number;
    };
    itemPrice: number;
    itemAttributes: {
        attributes: ItemAttributesAttribute[];
    };
}

interface ItemAttributesAttribute {
    name: string;
    value: string;
}

export interface SessionOrderPriceInfo {
    result_price: number;
    limit_mileage: number;
    price: PriceInfoPrice;
    discounts: PriceInfoDiscounts;
    limitations: PriceInfoLimitations;
    user_discounts: any[];
    vehicle_price: number;
    date_start: Date | string;
    date_end: Date | string;
    reason: string;
    start_data: number;
    end_data: number;
    preorder: boolean;
    type: string;
}

export interface PriceInfoLimitations {
    type: string;
    priceType: string;
    fine: LimitationsFine;
    value: number;
    addValueYear?: number;
}

interface LimitationsFine {
    count: number;
    price: number;
}

interface PriceInfoDiscounts {
    engines: Engine[];
    equipments: [];
    colors: [];
}

export interface Engine {
    eng_id: number;
    value: number;
    unit: string;
}

interface PriceInfoPrice {
    type: string;
    description: string;
    duration: number;
    price: number;
    period: number[];
}
