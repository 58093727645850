import {
    AtolGuide,
    CustomerPerson,
    DebtUnit,
    OrganizationDto,
    Receipt,
    Session,
    SessionOrder,
    VehicleItem,
} from '@shared/models';

export interface SessionItemDto {
    session: Session;
    childrenSessions: Session[];
    vehicle: VehicleItem;
    vehicles: VehicleItem[];
    customer: CustomerPerson;
    debts: DebtUnit[];
    orders: SessionOrder[];
    receipt: Receipt[];
    atol_guide: AtolGuide[];
    timeZone: number;
    organization?: OrganizationDto;
}
