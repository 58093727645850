export interface SessionParameters {
    vehiclePreparationHoursFreedom: number;
    vehiclePreparationHoursCountry: number;
    changeSessionStartMaxDaysFreedom: number;
    changeSessionStartMaxDaysCountry: number;
    minStartSessionTimeHour: number;
    maxStartSessionTimeHour: number;
    ofssetDayInSessionCount: number;
    ofssetDayInSessionProducts: string[];
}
