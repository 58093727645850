// export const TABLE_FILTER_CONDITIONS = [
//     { type: 'eq', name: 'Равно' },
//     { type: 'ne', name: 'Не равно' },
//     { type: 'gt', name: '>' },
//     { type: 'lt', name: '<' },
//     { type: 'gte', name: '>=' },
//     { type: 'lte', name: '<=' },
//     { type: 'max', name: 'Максимум' },
//     { type: 'min', name: 'Минимум' },
// ];
export type TableFilterCondition = 'eq' | 'ne' | 'gt' | 'lt' | 'gte' | 'lte' | 'max' | 'min';

export interface TableRow {
    [index: string]: any;
}

export interface TableFilterItem {
    column: string;
    condition: TableFilterCondition;
    filterValue: number | string;
    betweenValue?: number;
    betweenCondition?: TableFilterCondition;
}
