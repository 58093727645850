import { ENVIRONMENT_COMMON } from './environment.common';

export const environment = {
    ...ENVIRONMENT_COMMON,
    configuration: 'develop',
    production: false,
    showDevBanner: true,
    login: {
        name: null,
        pass: null,
    },
    baseUrl: 'https://devapi.mobility.hyundai.ru/',
    baseUrlFixed: 'https://devapi.mobility.hyundai.ru/',
};
