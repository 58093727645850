import { Moment } from 'moment';

export declare type OrganizationsBillTypes = 'advance' | 'other';

export interface OrganizationsBill {
    _id?: string;
    organizationId?: string;
    paymentReceiver?: string;
    invoiceType: OrganizationsBillTypes;
    invoiceNumber: string;
    invoiceDate: Moment | string;
    priceWTax?: boolean;
    paymentList: OrganizationsBillPosition[];
    status?: number;
    created_at?: Date | string;
    items?: number;
    amount?: number;
    amountWithTax?: number;
    paymentDate?: Date | string;
    paymentDetails?: string;
}

export interface OrganizationsBillPosition {
    name: string;
    count: number | string;
    units: string;
    price: number | string;
    priceWTax?: boolean;
    tax: number | string;
    amount: number;
    amountWithTax?: number;
}
