import { Sort } from '@angular/material/sort';
import { TableFilterItem } from '@shared/models';

export interface TableBase {
    page: number;
    onPage: number;
    total: number;
    search: string;
    filters: TableFilterItem[];
    columns: string[];
    sort: Sort;
}

export interface RefRow {
    i18n: { [index: string]: string };
    id: number;
}
