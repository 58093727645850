export interface User {
    _id?: string;
    email: string;
    roles: string[];
    number?: string;
    transport_park?: string;
    fio?: string;
    transport_park_enum?: string;
    isNew?: boolean;
}
