export interface VehicleInteriorRefs {
    interiorName: string[];
    interiorTrim: string[];
    interiorColor: string[];
    interiorColorHex: string[];
}

export interface VehicleEnginesRefs {
    engVol: string[];
    transmission: string[];
    drivetrain: string[];
    power: string[];
    fuel_type: string[];
    engine_type: string[];
    seat: number[];
    engCaption?: string[];
}

export interface VehicleEquipmentElementsRef {
    _id: string;

    items: string;
}
