import { ProjectId } from './project';
import { UserAdmin } from '@pages/mobility/settings/store/interfaces/user.interface';

export interface CustomerGalleryPhoto {
    img: string;
    thumb?: string;
    description?: string;
    url?: string;
}

export interface CustomerPhoto {
    name: string;
    url: string;
}

export interface CustomerScoring {
    timeUpdate: Date | string;
    status: string;
    description: string;
    applicationId: string;
    requestId: string;
}

interface CustomerRegistrationAddressDataElement {
    name: string;
    type: string;
}

export interface CustomerCreditCard {
    cardholderName: string;
    Pan: string;
    bindingId: string;
    expiration: string;
}

interface CustomerRegistrationAddressDataAdditionalElement {
    type: string;
    value: string;
}

interface CustomerRegistrationAddressData {
    additionalCodes: CustomerRegistrationAddressDataAdditionalElement[];
    region: CustomerRegistrationAddressDataElement;
    district: CustomerRegistrationAddressDataElement;
    city: CustomerRegistrationAddressDataElement;
    town: CustomerRegistrationAddressDataElement;
    street: CustomerRegistrationAddressDataElement;
    house: string;
    housing: string;
    block: string;
    roomNumber: string;
    zipCode: string;
}

export interface CustomerRegistration {
    issued: string;
    address: string;
    address_data: CustomerRegistrationAddressData;
    issue_date: string;
}

export interface CustomerPassport {
    first_name: string;
    last_name: string;
    middle_name: string;
    gender: string;
    birth_date: string;
    citizenship: string;
    place_date: string;
    passport_issued: string;
    passport_issue_date: string;
    passport_department_code: string;
    passport_number: string;
    passport_serial: string;
    photo?: CustomerPhoto[];
}

export interface CustomerDlicense {
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    birth_date?: string;
    issued: string;
    issue_date: string;
    expire_date: string;
    experience: string;
    dlicense_number: string;
    dlicense_serial: string;
    kbm?: number | string;
    kbm_warn?: string;
    kbm_approved?: boolean;
    photo?: CustomerPhoto[];
}

export interface CustomerSumSubScoringStory {
    date: Date;
    state: string;
    result: unknown;
}

export interface CustomerSumSubScoring {
    status: string;
    story: CustomerSumSubScoringStory[];
    scoringId?: string;
    statusDate: Date;
    reviewResult?: {
        moderationComment?: string;
        clientComment?: string;
        reviewAnswer?: string;
        rejectLabels?: string[];
        reviewRejectType?: string;
    };
}

export interface CustomerPerson {
    _id: string;
    customerId?: string;
    applications?: [
        {
            name: string;
            devices: [
                {
                    deviceId: string;
                    pushToken: string | null | undefined;
                    deviceName: string;
                },
            ];
            lastLogin: string;
            roles: Array<string>;
            dateRegistration: string;
        },
    ];
    number: number | null;
    phoneNumber: number | null;
    date_registration?: number;
    uploadingDocumentsDate?: string;
    dlicense?: CustomerDlicense;
    email?: string;
    last_id?: number;
    last_login?: string;
    passport?: CustomerPassport;
    place_registration?: CustomerRegistration;
    status?: number;
    operator?: string;
    operator_date?: number;
    ops?: number;
    scoring?: CustomerScoring;
    scoringDate?: string;
    ucsinfo?: CustomerCreditCard[];
    scoringSumsub?: CustomerSumSubScoring;
    groups?: string[];
    project?: ProjectId[];
    isDeleted: boolean;
    customerDuplicate?: {
        status?: string;
        error?: string;
        last_id?: number;
        passport?: {
            first_name?: string;
            last_name?: string;
        };
    };
    // Находится ли пользователь в стоп листе
    stopList?: boolean;
    /**
     * @deprecated
     */
    reason?: string;

    /**
     * @deprecated
     */
    smsGuids?: Record<string, string>;

    /**
     * @deprecated
     */
    date_login?: number;

    /**
     * @deprecated
     */
    balance?: 0;

    /**
     * @deprecated
     */
    dp_points?: number;

    /**
     * @deprecated
     */
    fcmTokens?: string[];

    /**
     * @deprecated
     */
    notification?: boolean;

    // предупреждения Cyberity (SumSub)
    tags: string[];
    warning: Array<{
        code: string;
        name: string;
    }>;
    agreements: {
        // NULL, True, False - если пользователь согласен получать рассылки, то True, если нет - False, если NULL - пока не принимал этого решения
        acceptPromoMessages: boolean | null;
        // дата изменения статуса
        acceptPromoMessagesLastChangeDate: string;
        acceptUserTerms: boolean | null;
        // дата изменения статуса
        acceptUserTermsLastChangeDate: string;
    };
    bachata?: {
        clientId?: string;
    };
}

export interface CustomerMessage {
    title?: string;
    body?: string;
    timeStamp: Date;
    read: boolean;
    htmlArea?: string;
}

export interface CustomerComment {
    _id: string;
    text: string;
    user?: UserAdmin;
    date_created: Date;
    date_updated?: Date;
}

interface SuggestionsCountryData {
    code: string; // Цифровой код страны
    alfa2: string; // Буквенный код альфа-2
    alfa3: string; // Буквенный код альфа-3
    name_short: string; // Краткое наименование страны
    name: string; // Полное официальное наименование страны
}

export interface SuggestionsCountry {
    value: string;
    unrestricted_value: string;
    data: SuggestionsCountryData;
}

interface SuggestionsFMSData {
    code: string; // Код подразделения
    name: string | null; // Название подразделения в творительном падеже («кем выдан?»)
    region_code: string; // Код региона (2 цифры)
    type: string; // Вид подразделения (1 цифра)
    // 0 — подразделение ФМС
    // 1 — ГУВД или МВД региона
    // 2 — УВД или ОВД района или города
    // 3 — отделение полиции
}

export interface SuggestionsFms {
    value: string;
    unrestricted_value: string;
    data: SuggestionsFMSData;
}

interface SuggestionsNameData {
    surname: string | null; // Фамилия
    name: string | null; // Имя
    patronymic: string | null; // Отчество
    gender: // Пол
    | 'MALE' //    — мужской
        | 'FEMALE' //  — женский
        | 'UNKNOWN'; // — не удалось однозначно определить;
    source: null; // Не используется
    qc: '0' | '1'; // 0 — все части ФИО известны
    // 1 — в ФИО есть неизвестная часть
}

export interface SuggestionsName {
    value: string;
    unrestricted_value: string;
    data: SuggestionsNameData;
}

export interface CustomerOperatorEdit {
    status: string;
    operatorName?: string;
    email?: string;
    editTimestamp?: string;
}
