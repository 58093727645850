export interface VehicleUpdateRequestDto {
    sim_id: string;
    color: string;
    interior: string;
    equipment: string;
    engine: string;
    transport_park: string;
    forSale?: boolean;
    catalogEnable?: boolean;
    withMileage?: boolean;
    city: string;
    manufacturer: string;
    model: string;
    project: string;
    ocnCode?: string;
    interiorCode?: string;
    exteriorCode?: string;
}
