interface FormValue {
    name: string;
    value: string;
}

export interface FineEditFormRef {
    statuses: FormValue[];
}

export interface FineEditFormData {
    _id: string;
    price: number;
    status: string;
    formRef?: FineEditFormRef;

    isCountryEnd: boolean;
    daysPayedEnd?: Date;
    daysEnd?: Date;
    daysDebt?: number;
    gasolineStart?: number;
    gasolineEnd?: number;
    gasolineDebt?: number;
    mileagePayedEnd?: number;
    mileageEnd?: number;
    mileageDebt?: number;
}
