export interface RemoveDriverResponseDto {
    success: boolean;
    message?:
        | 'invalid_request_dto'
        | 'vehicle_not_found'
        | 'session_not_found'
        | 'driver_not_found'
        | 'driver_remove_fail'
        | 'driver_remove_success'
        | 'no_settings';
}
