export interface FormValue {
    value: string;
    name: string;
}

export interface VehicleFormData {
    manufacturersList: FormValue[];
    parksList: FormValue[];
    modelsList: FormValue[];
    enginesList: FormValue[];
    equipmentList: FormValue[];
    colorsList: FormValue[];
    interiorColorsList: FormValue[];
}
