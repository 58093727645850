export interface VehicleCreateRequestDto {
    vin: string;
    sim_id: string;
    color: string;
    interior: string;
    equipment: string;
    engine: string;
    transport_park: string;
    forSale?: boolean;
    catalogEnable?: boolean;
    withMileage?: boolean;
    city: string;
    manufacturer: string;
    model: string;
    project: string;
}
